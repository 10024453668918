import React, { ReactElement } from 'react'
import { NextPageWithLayout } from '~pages/_app'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Box, Button, Heading, Img, Show, Text, useBreakpointValue, useStyleConfig } from '@chakra-ui/react'
import ScheduleIcon from '~ui/icons/ScheduleIcon'

import WorkoutIcon from '~ui/icons/WorkoutIcon'
import CallCoachIcon from '~ui/icons/CallCoachIcon'
import { routes } from '~components/sielbleu/utils/routes'
import Head from 'next/head'
import { BPCO } from '~components/sielbleu/utils/const'
import Layout from '~components/sielbleu/layout'

const Page: NextPageWithLayout = () => {
    const { t, lang } = useTranslation('sielbleu')
    const router = useRouter()
    const { query } = router
    const pageStyles = useStyleConfig('Landing', { variant: BPCO })

    const imgPath = '/images/sielbleu/landing'
    const devicesImage = useBreakpointValue({
        base: imgPath + '/devices_webmobile.png',
        md: imgPath + '/devices_web.png',
    })

    const partners = {
        link: 'https://www.clinea.fr/',
        name: 'clinea',
        path: '/images/sielbleu/bpco/logo-clinea-min.png',
    }

    const handleRegisterRedirection = () => {
        if (typeof window === 'undefined') return
        window.location = ('//' +
            process.env.NEXT_PUBLIC_SIELBLEU_DOMAIN +
            '/' +
            lang +
            routes.public.register +
            '?' +
            new URLSearchParams({
                ...query,
                type: 'BPCO',
            }).toString()) as any
    }

    return (
        <>
            <Head>
                <title>
                    Siel Bleu {t('sielbleu.landing.bpco.content')} - {t('sielbleu.landing.adapted-physical-activity')}
                </title>
                <meta name="description" content={t('sielbleu.landing.bpco.description.text')} />
                <meta property="og:title" content={t('sielbleu.landing.bpco.title')} />
                <meta property="og:site_name" content={'Siel Bleu ' + t('sielbleu.landing.bpco.content')} />
                <meta property="og:url" content={process.env.NEXT_PUBLIC_LANDING_BPCO_URL} />
                <meta property="og:description" content={t('sielbleu.landing.bpco.description.text')} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content={process.env.NEXT_PUBLIC_LANDING_BPCO_URL + '/images/sielbleu/bpco/header_image.jpg'}
                />
            </Head>
            <Box __css={pageStyles}>
                <Box className="header-banner">
                    <Heading className="main-title">{t('sielbleu.landing.bpco.title')}</Heading>
                    <Heading className="motivation-title">{t('sielbleu.landing.bpco.motivation')}</Heading>
                    <Button onClick={handleRegisterRedirection}>{t('sielbleu.landing.signup')}</Button>
                </Box>
                <Box className="container">
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">{t('sielbleu.landing.bpco.description.title')}</Heading>
                            <Text className="section">{t('sielbleu.landing.bpco.description.text')}</Text>
                        </Box>
                        <Img src={devicesImage} alt="devices" />
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.bpco.details.title')}</Heading>
                        <Box className="card-container">
                            <Box className="card-blue">
                                <ScheduleIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.bpco.details.card-1.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.bpco.details.card-1.text')}</Text>
                            </Box>
                            <Box className="card-blue">
                                <WorkoutIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.bpco.details.card-2.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.bpco.details.card-2.text')}</Text>
                            </Box>
                            <Box className="card-blue">
                                <CallCoachIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.bpco.details.card-3.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.bpco.details.card-3.text')}</Text>
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.bpco.partners')}</Heading>
                        <Show>
                            <Box display="flex">
                                <a href={partners.link} key={partners.name} target="_blank" rel="noreferrer">
                                    <Img src={partners.path} alt={partners.name} />
                                </a>
                            </Box>
                        </Show>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const PageLanding = Page

PageLanding.getLayout = function getLayout(page: ReactElement) {
    return <Layout>{page}</Layout>
}

export default PageLanding
